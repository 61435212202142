const Ankerslogen = ({ isMobile, ...props }: { isMobile: boolean }) => {
  return (
    <svg
      {...props}
      width="445"
      height="21"
      viewBox="0 0 445 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.5466 10.8031C81.4708 9.33123 82.3174 7.79201 82.1827 5.81987C81.9807 2.94343 79.6524 1.00977 77.0066 1.00977H69.3965V17.6431H72.4175V10.8031H75.9868L80.2585 17.6431H83.8279L79.537 10.8031H79.5466ZM79.2483 5.82948C79.2483 7.09935 78.3536 7.84973 76.9874 7.84973H72.4175V3.94393H76.9874C78.7096 3.94393 79.2483 5.13683 79.2483 5.81987"
        fill="#00BEFA"
      />
      <path
        d="M46.373 11.4285L50.2695 17.6432H53.8678L48.3261 8.83105L46.373 11.4285Z"
        fill="#00BEFA"
      />
      <path
        d="M52.7708 1.00977H48.9321L41.0237 11.5246V1.00977H37.9834V16.1712C37.9834 16.7292 38.3105 17.4218 39.1764 17.6142C39.5227 17.6912 40.2155 17.6142 40.6292 17.0659C40.6773 16.9985 52.7708 1.00977 52.7708 1.00977Z"
        fill="#00BEFA"
      />
      <path
        d="M65.4805 7.84961H59.5059V10.7934H65.4805V7.84961Z"
        fill="#00BEFA"
      />
      <path
        d="M67.0103 1.00977H55.1572V16.1712C55.1572 16.9889 55.8211 17.6527 56.6388 17.6527H67.0103V14.7089H58.1878V3.95354H67.0103V1.00977Z"
        fill="#00BEFA"
      />
      <path
        d="M31.9129 1.00977V11.5246L24.0045 1.00977H20.1465V17.6335H23.1771V4.99253C26.7657 9.73528 32.2785 17.0274 32.3074 17.0659C32.7211 17.6142 33.4138 17.6912 33.7602 17.6142C34.6261 17.4122 34.9532 16.7292 34.9532 16.1712V1.00977H31.9129Z"
        fill="#00BEFA"
      />
      <path
        d="M7.77372 1.9043L0 17.6429H3.38657L9.24573 5.82934L15.0953 17.6429H18.453L10.737 1.95241C10.4484 1.37519 9.86147 1 9.21687 1C8.60113 1 8.04311 1.34633 7.77372 1.89469"
        fill="#00BEFA"
      />
      <path
        d="M11.9877 15.2958H9.98656C9.98656 15.2958 9.94808 15.2766 9.9577 15.2574L10.3522 10.986C10.3522 10.986 10.3137 10.9379 10.2944 10.9667L5.97461 16.6138C5.97461 16.6138 5.97461 16.6619 6.00347 16.6619H8.00465C8.00465 16.6619 8.04313 16.6811 8.03351 16.7004L7.63905 20.9718C7.63905 20.9718 7.67753 21.0198 7.69677 20.991L12.0166 15.3439C12.0166 15.3439 12.0166 15.2958 11.9877 15.2958Z"
        fill="#00BEFA"
      />
      <line x1="94.5" y1="1" x2="94.5" y2="17" stroke="#00BEFA" />
      <path
        d="M108.315 17.1601C108.204 17.1601 108.127 17.0872 108.127 16.9715V6.5372C108.127 6.46432 108.088 6.42574 108.015 6.42574H104.689C104.577 6.42574 104.5 6.35287 104.5 6.23712V4.62954C104.5 4.51808 104.573 4.44092 104.689 4.44092H113.777C113.888 4.44092 113.965 4.5138 113.965 4.62954V6.23712C113.965 6.34858 113.893 6.42574 113.777 6.42574H110.45C110.377 6.42574 110.339 6.46432 110.339 6.5372V16.9715C110.339 17.0829 110.266 17.1601 110.15 17.1601H108.32H108.315Z"
        fill="#00BEFA"
      />
      <path
        d="M121.428 17.1602C121.317 17.1602 121.24 17.0873 121.24 16.9716V11.7759C121.24 10.6184 120.661 9.81248 119.538 9.81248C118.415 9.81248 117.819 10.6141 117.819 11.7759V16.9716C117.819 17.083 117.746 17.1602 117.63 17.1602H115.911C115.8 17.1602 115.723 17.0873 115.723 16.9716V4.63394C115.723 4.52248 115.796 4.44531 115.911 4.44531H117.63C117.742 4.44531 117.819 4.51819 117.819 4.63394V8.9894H117.836C118.23 8.42782 119.015 7.94341 120.211 7.94341C122.136 7.94341 123.332 9.36236 123.332 11.2915V16.9759C123.332 17.0873 123.259 17.1645 123.143 17.1645H121.424L121.428 17.1602Z"
        fill="#00BEFA"
      />
      <path
        d="M125.66 14.8629C125.471 14.2841 125.343 13.5897 125.343 12.6551C125.343 11.7206 125.454 11.009 125.643 10.4302C126.149 8.86124 127.533 7.94385 129.325 7.94385C131.117 7.94385 132.523 8.89554 133.025 10.4302C133.231 11.0647 133.325 11.682 133.325 13.1395C133.325 13.251 133.252 13.3282 133.119 13.3282H127.568C127.495 13.3282 127.456 13.3667 127.456 13.4396C127.456 13.7011 127.512 13.924 127.589 14.1298C127.889 15.0086 128.597 15.493 129.608 15.493C130.62 15.493 131.272 15.1201 131.722 14.6314C131.816 14.5371 131.91 14.5199 132 14.5928L133.102 15.5659C133.196 15.6388 133.196 15.7331 133.119 15.8274C132.352 16.7234 131.083 17.3621 129.42 17.3621C127.512 17.3621 126.166 16.4276 125.66 14.8586V14.8629ZM131.1 10.8804C130.856 10.1516 130.183 9.7229 129.342 9.7229C128.502 9.7229 127.808 10.1516 127.568 10.8804C127.495 11.1033 127.456 11.3305 127.456 11.6477C127.456 11.7206 127.495 11.7592 127.568 11.7592H131.1C131.173 11.7592 131.212 11.7206 131.212 11.6477C131.212 11.3305 131.173 11.1076 131.1 10.8804Z"
        fill="#00BEFA"
      />
      <path
        d="M149.036 17.1602C148.925 17.1602 148.848 17.0873 148.813 16.9716L146.383 8.27778H146.344L143.896 16.9716C143.858 17.083 143.785 17.1602 143.673 17.1602H142.272C142.16 17.1602 142.083 17.0873 142.049 16.9716L138.928 4.63394C138.911 4.52248 138.945 4.44531 139.078 4.44531H140.836C140.968 4.44531 141.041 4.51819 141.058 4.63394L143.078 13.1948H143.116L145.491 4.63394C145.53 4.52248 145.603 4.44531 145.714 4.44531H147.022C147.154 4.44531 147.21 4.51819 147.244 4.63394L149.675 13.1948H149.714L151.677 4.63394C151.694 4.50104 151.789 4.44531 151.9 4.44531H153.64C153.773 4.44531 153.808 4.51819 153.791 4.63394L150.687 16.9716C150.67 17.083 150.575 17.1602 150.464 17.1602H149.045H149.036Z"
        fill="#00BEFA"
      />
      <path
        d="M154.734 14.88C154.528 14.2456 154.434 13.6454 154.434 12.6551C154.434 11.6649 154.528 11.0647 154.734 10.4302C155.24 8.86124 156.624 7.94385 158.455 7.94385C160.285 7.94385 161.653 8.86124 162.154 10.4302C162.36 11.0647 162.454 11.6649 162.454 12.6551C162.454 13.6454 162.36 14.2456 162.154 14.88C161.648 16.449 160.268 17.3664 158.455 17.3664C156.641 17.3664 155.24 16.449 154.734 14.88ZM160.135 14.3184C160.285 13.8512 160.324 13.4225 160.324 12.6551C160.324 11.8878 160.285 11.4762 160.135 10.9918C159.874 10.2416 159.295 9.81293 158.45 9.81293C157.606 9.81293 157.01 10.2416 156.749 10.9918C156.598 11.4762 156.56 11.8878 156.56 12.6551C156.56 13.4225 156.598 13.8512 156.749 14.3184C157.01 15.0643 157.606 15.4973 158.45 15.4973C159.295 15.4973 159.869 15.0686 160.135 14.3184Z"
        fill="#00BEFA"
      />
      <path
        d="M164.753 17.1606C164.642 17.1606 164.564 17.0878 164.564 16.972V8.33395C164.564 8.2225 164.637 8.14533 164.753 8.14533H166.472C166.584 8.14533 166.661 8.21821 166.661 8.33395V9.1013H166.678C167.107 8.41112 167.874 7.94385 169.014 7.94385C169.726 7.94385 170.433 8.2225 170.9 8.68977C170.995 8.78408 171.012 8.85695 170.939 8.95127L169.949 10.203C169.876 10.2973 169.782 10.3145 169.687 10.2416C169.276 9.98011 168.847 9.81293 168.358 9.81293C167.179 9.81293 166.656 10.6532 166.656 12.0935V16.972C166.656 17.0835 166.584 17.1606 166.468 17.1606H164.749H164.753Z"
        fill="#00BEFA"
      />
      <path
        d="M174.985 17.2716C173.227 17.2716 172.516 16.4314 172.516 14.691V4.63394C172.516 4.52248 172.589 4.44531 172.704 4.44531H174.423C174.535 4.44531 174.612 4.51819 174.612 4.63394V14.5795C174.612 15.1968 174.856 15.4026 175.396 15.4026H175.808C175.919 15.4026 175.997 15.4754 175.997 15.5912V17.0873C175.997 17.1988 175.924 17.2759 175.808 17.2759H174.985V17.2716Z"
        fill="#00BEFA"
      />
      <path
        d="M183.421 17.1603C183.309 17.1603 183.232 17.0874 183.232 16.9717V16.2986H183.215C182.786 16.9159 182.036 17.3661 180.823 17.3661C179.288 17.3661 178.169 16.6373 177.702 15.1969C177.479 14.5067 177.385 13.868 177.385 12.6548C177.385 11.4416 177.479 10.8029 177.702 10.1127C178.169 8.67228 179.292 7.94351 180.823 7.94351C182.036 7.94351 182.786 8.39363 183.215 9.01094H183.232V4.63833C183.232 4.52687 183.305 4.44971 183.421 4.44971H185.14C185.251 4.44971 185.328 4.52258 185.328 4.63833V16.976C185.328 17.0874 185.255 17.1646 185.14 17.1646H183.421V17.1603ZM183.048 14.3352C183.181 13.9237 183.236 13.4736 183.236 12.6505C183.236 11.8274 183.181 11.3773 183.048 10.97C182.803 10.2198 182.225 9.81259 181.367 9.81259C180.51 9.81259 179.948 10.2241 179.704 10.97C179.571 11.3816 179.515 11.8317 179.515 12.6505C179.515 13.4693 179.571 13.9237 179.704 14.3352C179.948 15.0854 180.527 15.4927 181.367 15.4927C182.207 15.4927 182.808 15.0812 183.048 14.3352Z"
        fill="#00BEFA"
      />
      <path
        d="M187.52 7.88767V4.63394C187.52 4.52248 187.592 4.44531 187.708 4.44531H189.221C189.333 4.44531 189.41 4.51819 189.41 4.63394V6.39156C189.41 6.58018 189.371 6.6702 189.26 6.78595L187.987 7.9434C187.892 8.03772 187.798 8.0763 187.708 8.0763C187.597 8.0763 187.52 8.00342 187.52 7.88767Z"
        fill="#00BEFA"
      />
      <path
        d="M190.174 15.9474C190.101 15.8746 190.101 15.7588 190.174 15.6859L191.241 14.5628C191.314 14.4899 191.43 14.4899 191.503 14.5628C192.159 15.1629 193.205 15.5916 194.139 15.5916C195.241 15.5916 195.802 15.1415 195.802 14.5242C195.802 13.9841 195.485 13.6454 194.306 13.5339L193.333 13.4396C191.503 13.251 190.568 12.3165 190.568 10.786C190.568 9.04558 191.82 7.94385 194.066 7.94385C195.468 7.94385 196.647 8.41112 197.449 9.04558C197.543 9.12274 197.543 9.2342 197.487 9.30708L196.57 10.4088C196.497 10.5031 196.403 10.5031 196.308 10.4474C195.747 10.053 194.851 9.70147 193.951 9.70147C193.05 9.70147 192.587 10.0959 192.587 10.636C192.587 11.1762 192.905 11.4762 194.062 11.5877L195.035 11.682C196.921 11.8706 197.822 12.8395 197.822 14.3184C197.822 16.1146 196.514 17.3664 194.045 17.3664C192.193 17.3664 190.924 16.6376 190.174 15.9474Z"
        fill="#00BEFA"
      />
      <path
        d="M254.143 4.63394C254.143 4.52248 254.216 4.44531 254.332 4.44531H255.995C256.162 4.44531 256.256 4.50104 256.312 4.63394L259.81 12.4661H259.883L263.321 4.63394C263.377 4.50104 263.471 4.44531 263.638 4.44531H265.285C265.396 4.44531 265.473 4.51819 265.473 4.63394V16.9716C265.473 17.083 265.4 17.1602 265.285 17.1602H263.66C263.548 17.1602 263.471 17.0873 263.471 16.9716V9.19517H263.398L260.706 15.1797C260.633 15.3468 260.518 15.424 260.35 15.424H259.266C259.099 15.424 258.987 15.3511 258.91 15.1797L256.218 9.19517H256.145V16.9716C256.145 17.083 256.072 17.1602 255.956 17.1602H254.327C254.216 17.1602 254.139 17.0873 254.139 16.9716V4.63394H254.143Z"
        fill="#00BEFA"
      />
      <path
        d="M267.976 14.88C267.77 14.2456 267.676 13.6454 267.676 12.6551C267.676 11.6649 267.77 11.0647 267.976 10.4302C268.482 8.86124 269.866 7.94385 271.697 7.94385C273.527 7.94385 274.895 8.86124 275.396 10.4302C275.602 11.0647 275.697 11.6649 275.697 12.6551C275.697 13.6454 275.602 14.2456 275.396 14.88C274.891 16.449 273.51 17.3664 271.697 17.3664C269.884 17.3664 268.482 16.449 267.976 14.88ZM273.377 14.3184C273.527 13.8512 273.566 13.4225 273.566 12.6551C273.566 11.8878 273.527 11.4762 273.377 10.9918C273.116 10.2416 272.537 9.81293 271.693 9.81293C270.848 9.81293 270.252 10.2416 269.991 10.9918C269.841 11.4762 269.802 11.8878 269.802 12.6551C269.802 13.4225 269.841 13.8512 269.991 14.3184C270.252 15.0643 270.848 15.4973 271.693 15.4973C272.537 15.4973 273.112 15.0686 273.377 14.3184Z"
        fill="#00BEFA"
      />
      <path
        d="M279.92 16.2985H279.903V16.9716C279.903 17.083 279.83 17.1602 279.714 17.1602H277.995C277.884 17.1602 277.807 17.0873 277.807 16.9716V4.63394C277.807 4.52248 277.88 4.44531 277.995 4.44531H279.714C279.826 4.44531 279.903 4.51819 279.903 4.63394V9.00655H279.92C280.349 8.38924 281.099 7.93912 282.312 7.93912C283.847 7.93912 284.966 8.66789 285.433 10.1083C285.656 10.7985 285.75 11.4372 285.75 12.6504C285.75 13.8636 285.656 14.5023 285.433 15.1925C284.966 16.6329 283.843 17.3617 282.312 17.3617C281.099 17.3617 280.349 16.9115 279.92 16.2942V16.2985ZM283.435 14.3351C283.568 13.9236 283.624 13.4735 283.624 12.6504C283.624 11.8273 283.568 11.3772 283.435 10.9699C283.191 10.2197 282.612 9.81248 281.772 9.81248C280.932 9.81248 280.332 10.224 280.092 10.9699C279.959 11.3815 279.903 11.8316 279.903 12.6504C279.903 13.4692 279.959 13.9236 280.092 14.3351C280.336 15.0853 280.915 15.4926 281.772 15.4926C282.629 15.4926 283.195 15.0811 283.435 14.3351Z"
        fill="#00BEFA"
      />
      <path
        d="M288.094 6.44728C287.982 6.44728 287.905 6.37441 287.905 6.25867V4.63394C287.905 4.52248 287.978 4.44531 288.094 4.44531H289.813C289.924 4.44531 290.002 4.51819 290.002 4.63394V6.25867C290.002 6.37012 289.929 6.44728 289.813 6.44728H288.094ZM288.094 17.1602C287.982 17.1602 287.905 17.0873 287.905 16.9716V8.33351C287.905 8.22205 287.978 8.14489 288.094 8.14489H289.813C289.924 8.14489 290.002 8.21777 290.002 8.33351V16.9716C290.002 17.083 289.929 17.1602 289.813 17.1602H288.094Z"
        fill="#00BEFA"
      />
      <path
        d="M294.928 17.2716C293.171 17.2716 292.459 16.4314 292.459 14.691V4.63394C292.459 4.52248 292.532 4.44531 292.648 4.44531H294.367C294.478 4.44531 294.555 4.51819 294.555 4.63394V14.5795C294.555 15.1968 294.8 15.4026 295.34 15.4026H295.751C295.863 15.4026 295.94 15.4754 295.94 15.5912V17.0873C295.94 17.1988 295.867 17.2759 295.751 17.2759H294.928V17.2716Z"
        fill="#00BEFA"
      />
      <path
        d="M297.581 14.8629C297.392 14.2841 297.264 13.5897 297.264 12.6551C297.264 11.7206 297.375 11.009 297.564 10.4302C298.07 8.86124 299.454 7.94385 301.246 7.94385C303.038 7.94385 304.444 8.89554 304.946 10.4302C305.152 11.0647 305.246 11.682 305.246 13.1395C305.246 13.251 305.173 13.3282 305.04 13.3282H299.489C299.416 13.3282 299.377 13.3667 299.377 13.4396C299.377 13.7011 299.433 13.924 299.51 14.1298C299.81 15.0086 300.517 15.493 301.529 15.493C302.541 15.493 303.192 15.1201 303.643 14.6314C303.737 14.5371 303.831 14.5199 303.921 14.5928L305.023 15.5659C305.117 15.6388 305.117 15.7331 305.04 15.8274C304.273 16.7234 303.004 17.3621 301.34 17.3621C299.433 17.3621 298.087 16.4276 297.581 14.8586V14.8629ZM303.021 10.8804C302.777 10.1516 302.104 9.7229 301.263 9.7229C300.423 9.7229 299.729 10.1516 299.489 10.8804C299.416 11.1033 299.377 11.3305 299.377 11.6477C299.377 11.7206 299.416 11.7592 299.489 11.7592H303.021C303.094 11.7592 303.132 11.7206 303.132 11.6477C303.132 11.3305 303.094 11.1076 303.021 10.8804Z"
        fill="#00BEFA"
      />
      <path
        d="M311.569 10.8031C311.569 8.78394 311.642 8.14948 311.904 7.36499C312.56 5.29014 314.167 4.24414 316.315 4.24414C318.351 4.24414 319.886 5.29014 320.615 6.97489C320.653 7.0692 320.615 7.1635 320.52 7.1978L318.93 7.96515C318.818 8.02088 318.724 7.96515 318.686 7.87084C318.274 6.97488 317.562 6.22468 316.311 6.22468C315.059 6.22468 314.386 6.88057 314.03 7.90942C313.863 8.43242 313.807 8.93828 313.807 10.8074C313.807 12.6764 313.863 13.1823 314.03 13.7053C314.386 14.7341 315.097 15.39 316.311 15.39C317.524 15.39 318.274 14.6441 318.686 13.7439C318.724 13.6495 318.818 13.5938 318.93 13.6495L320.52 14.4169C320.615 14.4555 320.653 14.5498 320.615 14.6398C319.886 16.3246 318.351 17.3706 316.315 17.3706C314.163 17.3706 312.555 16.3246 311.904 14.2497C311.642 13.4652 311.569 12.8308 311.569 10.8116V10.8031Z"
        fill="#00BEFA"
      />
      <path
        d="M328.223 17.1602C328.112 17.1602 328.035 17.0873 328.035 16.9716V11.7759C328.035 10.6184 327.456 9.81248 326.333 9.81248C325.21 9.81248 324.614 10.6141 324.614 11.7759V16.9716C324.614 17.083 324.541 17.1602 324.425 17.1602H322.706C322.595 17.1602 322.518 17.0873 322.518 16.9716V4.63394C322.518 4.52248 322.59 4.44531 322.706 4.44531H324.425C324.537 4.44531 324.614 4.51819 324.614 4.63394V8.9894H324.631C325.025 8.42782 325.81 7.94341 327.006 7.94341C328.931 7.94341 330.127 9.36236 330.127 11.2915V16.9759C330.127 17.0873 330.054 17.1645 329.938 17.1645H328.219L328.223 17.1602Z"
        fill="#00BEFA"
      />
      <path
        d="M337.86 17.16C337.749 17.16 337.672 17.0872 337.672 16.9714V16.3155H337.655C337.226 16.9328 336.381 17.3615 335.112 17.3615C333.466 17.3615 332.086 16.4999 332.086 14.6136C332.086 12.7274 333.471 11.7543 335.695 11.7543H337.526C337.599 11.7543 337.637 11.7157 337.637 11.6428V11.2141C337.637 10.1853 337.153 9.71802 335.635 9.71802C334.624 9.71802 334.006 9.99666 333.578 10.3353C333.483 10.4082 333.372 10.3911 333.316 10.2967L332.626 9.06212C332.57 8.96781 332.587 8.87351 332.665 8.81778C333.393 8.29478 334.384 7.93896 335.863 7.93896C338.611 7.93896 339.622 8.8735 339.622 11.0812V16.9714C339.622 17.0829 339.549 17.16 339.434 17.16H337.865H337.86ZM337.637 14.1292V13.439C337.637 13.3662 337.599 13.3276 337.526 13.3276H336.03C334.684 13.3276 334.084 13.7005 334.084 14.5408C334.084 15.2867 334.628 15.6639 335.635 15.6639C336.926 15.6639 337.637 15.1581 337.637 14.1292Z"
        fill="#00BEFA"
      />
      <path
        d="M342.268 17.1606C342.156 17.1606 342.079 17.0878 342.079 16.972V8.33395C342.079 8.2225 342.152 8.14533 342.268 8.14533H343.987C344.098 8.14533 344.175 8.21821 344.175 8.33395V9.1013H344.193C344.621 8.41112 345.389 7.94385 346.529 7.94385C347.241 7.94385 347.948 8.2225 348.415 8.68977C348.509 8.78408 348.527 8.85695 348.454 8.95127L347.463 10.203C347.391 10.2973 347.296 10.3145 347.202 10.2416C346.79 9.98011 346.362 9.81293 345.873 9.81293C344.694 9.81293 344.171 10.6532 344.171 12.0935V16.972C344.171 17.0835 344.098 17.1606 343.982 17.1606H342.263H342.268Z"
        fill="#00BEFA"
      />
      <path
        d="M349.755 19.3855C349.682 19.2912 349.682 19.2183 349.772 19.124L350.874 18.0223C350.968 17.928 351.062 17.928 351.152 18.0223C351.714 18.5624 352.365 18.8625 353.116 18.8625C354.5 18.8625 355.135 18.1166 355.135 16.7105V15.8703H355.118C354.706 16.5047 353.939 16.9163 352.781 16.9163C351.268 16.9163 350.183 16.1489 349.716 14.73C349.493 14.0398 349.416 13.4225 349.416 12.4322C349.416 11.4419 349.489 10.8418 349.716 10.1516C350.183 8.7112 351.268 7.94385 352.781 7.94385C353.939 7.94385 354.706 8.35539 355.118 9.01128H355.135V8.33824C355.135 8.22678 355.212 8.14962 355.323 8.14962H357.042C357.154 8.14962 357.231 8.22249 357.231 8.33824V16.6033C357.231 19.184 355.791 20.7359 353.026 20.7359C351.774 20.7359 350.445 20.2129 349.755 19.3898V19.3855ZM354.933 13.9069C355.083 13.4954 355.139 13.0452 355.139 12.4279C355.139 11.8106 355.083 11.3819 354.933 10.9532C354.689 10.2245 354.187 9.81293 353.326 9.81293C352.464 9.81293 351.98 10.2245 351.735 10.9532C351.585 11.3819 351.529 11.832 351.529 12.4279C351.529 13.0238 351.585 13.4954 351.735 13.9069C351.98 14.6528 352.481 15.0643 353.326 15.0643C354.17 15.0643 354.689 14.6528 354.933 13.9069Z"
        fill="#00BEFA"
      />
      <path
        d="M359.93 6.44728C359.818 6.44728 359.741 6.37441 359.741 6.25867V4.63394C359.741 4.52248 359.814 4.44531 359.93 4.44531H361.649C361.76 4.44531 361.837 4.51819 361.837 4.63394V6.25867C361.837 6.37012 361.765 6.44728 361.649 6.44728H359.93ZM359.93 17.1602C359.818 17.1602 359.741 17.0873 359.741 16.9716V8.33351C359.741 8.22205 359.814 8.14489 359.93 8.14489H361.649C361.76 8.14489 361.837 8.21777 361.837 8.33351V16.9716C361.837 17.083 361.765 17.1602 361.649 17.1602H359.93Z"
        fill="#00BEFA"
      />
      <path
        d="M370.024 17.1606C369.913 17.1606 369.836 17.0878 369.836 16.972V11.7763C369.836 10.6189 369.257 9.81293 368.134 9.81293C367.011 9.81293 366.415 10.6146 366.415 11.7763V16.972C366.415 17.0835 366.342 17.1606 366.226 17.1606H364.507C364.396 17.1606 364.318 17.0878 364.318 16.972V8.33395C364.318 8.2225 364.391 8.14533 364.507 8.14533H366.226C366.337 8.14533 366.415 8.21821 366.415 8.33395V8.98985H366.432C366.826 8.42827 367.611 7.94385 368.807 7.94385C370.732 7.94385 371.928 9.3628 371.928 11.2919V16.9763C371.928 17.0878 371.855 17.1649 371.739 17.1649H370.02L370.024 17.1606Z"
        fill="#00BEFA"
      />
      <path
        d="M374.286 19.3855C374.213 19.2912 374.213 19.2183 374.303 19.124L375.405 18.0223C375.499 17.928 375.593 17.928 375.683 18.0223C376.245 18.5624 376.897 18.8625 377.647 18.8625C379.032 18.8625 379.666 18.1166 379.666 16.7105V15.8703H379.649C379.237 16.5047 378.47 16.9163 377.312 16.9163C375.799 16.9163 374.715 16.1489 374.247 14.73C374.024 14.0398 373.947 13.4225 373.947 12.4322C373.947 11.4419 374.02 10.8418 374.247 10.1516C374.715 8.7112 375.799 7.94385 377.312 7.94385C378.47 7.94385 379.237 8.35539 379.649 9.01128H379.666V8.33824C379.666 8.22678 379.743 8.14962 379.855 8.14962H381.574C381.685 8.14962 381.762 8.22249 381.762 8.33824V16.6033C381.762 19.184 380.322 20.7359 377.557 20.7359C376.305 20.7359 374.976 20.2129 374.286 19.3898V19.3855ZM379.464 13.9069C379.615 13.4954 379.67 13.0452 379.67 12.4279C379.67 11.8106 379.615 11.3819 379.464 10.9532C379.22 10.2245 378.719 9.81293 377.857 9.81293C376.995 9.81293 376.511 10.2245 376.266 10.9532C376.116 11.3819 376.061 11.832 376.061 12.4279C376.061 13.0238 376.116 13.4954 376.266 13.9069C376.511 14.6528 377.012 15.0643 377.857 15.0643C378.701 15.0643 379.22 14.6528 379.464 13.9069Z"
        fill="#00BEFA"
      />
      <path
        d="M388.946 4.63394C388.946 4.52248 389.019 4.44531 389.134 4.44531H393.863C396.555 4.44531 397.828 5.79139 397.828 7.82766C397.828 9.26805 397.155 10.0911 396.349 10.5198V10.5584C397.134 10.8585 398.051 11.8659 398.051 13.4177C398.051 15.9427 396.461 17.1559 393.674 17.1559H389.13C389.019 17.1559 388.941 17.083 388.941 16.9673V4.62965L388.946 4.63394ZM393.807 9.73961C394.93 9.73961 395.62 9.06657 395.62 8.0763C395.62 7.08603 394.93 6.39156 393.807 6.39156H391.265C391.192 6.39156 391.153 6.43014 391.153 6.50301V9.62386C391.153 9.70102 391.192 9.73532 391.265 9.73532H393.807V9.73961ZM391.265 15.2182H393.918C395.153 15.2182 395.843 14.5066 395.843 13.4435C395.843 12.3803 395.153 11.6858 393.918 11.6858H391.265C391.192 11.6858 391.153 11.7244 391.153 11.7973V15.1068C391.153 15.1797 391.192 15.2182 391.265 15.2182Z"
        fill="#00BEFA"
      />
      <path
        d="M400.371 17.1606C400.26 17.1606 400.183 17.0878 400.183 16.972V8.33395C400.183 8.2225 400.255 8.14533 400.371 8.14533H402.09C402.202 8.14533 402.279 8.21821 402.279 8.33395V9.1013H402.296C402.725 8.41112 403.492 7.94385 404.632 7.94385C405.344 7.94385 406.051 8.2225 406.519 8.68977C406.613 8.78408 406.63 8.85695 406.557 8.95127L405.567 10.203C405.494 10.2973 405.4 10.3145 405.305 10.2416C404.894 9.98011 404.465 9.81293 403.977 9.81293C402.798 9.81293 402.275 10.6532 402.275 12.0935V16.972C402.275 17.0835 402.202 17.1606 402.086 17.1606H400.367H400.371Z"
        fill="#00BEFA"
      />
      <path
        d="M413.477 17.16C413.365 17.16 413.288 17.0872 413.288 16.9714V16.3155H413.271C412.842 16.9328 411.998 17.3615 410.729 17.3615C409.083 17.3615 407.702 16.4999 407.702 14.6136C407.702 12.7274 409.087 11.7543 411.312 11.7543H413.142C413.215 11.7543 413.254 11.7157 413.254 11.6428V11.2141C413.254 10.1853 412.769 9.71802 411.252 9.71802C410.24 9.71802 409.623 9.99666 409.194 10.3353C409.1 10.4082 408.988 10.3911 408.932 10.2967L408.242 9.06212C408.187 8.96781 408.204 8.87351 408.281 8.81778C409.01 8.29478 410 7.93896 411.479 7.93896C414.227 7.93896 415.238 8.8735 415.238 11.0812V16.9714C415.238 17.0829 415.166 17.16 415.05 17.16H413.481H413.477ZM413.254 14.1292V13.439C413.254 13.3662 413.215 13.3276 413.142 13.3276H411.646C410.3 13.3276 409.7 13.7005 409.7 14.5408C409.7 15.2867 410.244 15.6639 411.252 15.6639C412.542 15.6639 413.254 15.1581 413.254 14.1292Z"
        fill="#00BEFA"
      />
      <path
        d="M423.396 17.1606C423.285 17.1606 423.208 17.0878 423.208 16.972V11.7763C423.208 10.6189 422.629 9.81293 421.506 9.81293C420.383 9.81293 419.787 10.6146 419.787 11.7763V16.972C419.787 17.0835 419.714 17.1606 419.598 17.1606H417.879C417.768 17.1606 417.69 17.0878 417.69 16.972V8.33395C417.69 8.2225 417.763 8.14533 417.879 8.14533H419.598C419.71 8.14533 419.787 8.21821 419.787 8.33395V8.98985H419.804C420.198 8.42827 420.983 7.94385 422.179 7.94385C424.104 7.94385 425.3 9.3628 425.3 11.2919V16.9763C425.3 17.0878 425.222 17.1649 425.111 17.1649H423.392L423.396 17.1606Z"
        fill="#00BEFA"
      />
      <path
        d="M433.406 17.1603C433.295 17.1603 433.217 17.0874 433.217 16.9717V16.2986H433.2C432.772 16.9159 432.021 17.3661 430.808 17.3661C429.273 17.3661 428.155 16.6373 427.687 15.1969C427.464 14.5067 427.37 13.868 427.37 12.6548C427.37 11.4416 427.464 10.8029 427.687 10.1127C428.155 8.67228 429.278 7.94351 430.808 7.94351C432.021 7.94351 432.772 8.39363 433.2 9.01094H433.217V4.63833C433.217 4.52687 433.29 4.44971 433.406 4.44971H435.125C435.237 4.44971 435.314 4.52258 435.314 4.63833V16.976C435.314 17.0874 435.241 17.1646 435.125 17.1646H433.406V17.1603ZM433.033 14.3352C433.166 13.9237 433.222 13.4736 433.222 12.6505C433.222 11.8274 433.166 11.3773 433.033 10.97C432.789 10.2198 432.21 9.81259 431.353 9.81259C430.495 9.81259 429.934 10.2241 429.689 10.97C429.556 11.3816 429.501 11.8317 429.501 12.6505C429.501 13.4693 429.556 13.9237 429.689 14.3352C429.934 15.0854 430.512 15.4927 431.353 15.4927C432.193 15.4927 432.793 15.0812 433.033 14.3352Z"
        fill="#00BEFA"
      />
      <path
        d="M237.884 17.0756L238.45 13.779C238.476 13.6333 238.377 13.5347 238.227 13.5347H234.956C234.81 13.5347 234.712 13.6333 234.686 13.779L234.12 17.0756C234.095 17.2214 234.193 17.32 234.343 17.32H237.614C237.76 17.32 237.858 17.2214 237.884 17.0756Z"
        fill="#00BEFA"
      />
      <path
        d="M246.113 17.0839L248.903 1.33821C248.929 1.19674 248.831 1.09814 248.689 1.09814H245.47C245.328 1.09814 245.182 1.1453 245.041 1.23961L241.822 3.29303C241.701 3.38734 241.654 3.48594 241.629 3.6274L241.054 6.9926C241.007 7.18551 241.148 7.23267 241.294 7.13407L244.278 5.29928H244.325L242.224 17.0839C242.199 17.2254 242.297 17.324 242.439 17.324H245.851C245.993 17.324 246.091 17.2297 246.113 17.0839Z"
        fill="#00BEFA"
      />
      <path
        d="M219.554 1.1459C219.533 1.12017 219.49 1.10303 219.443 1.10303H216.215C216.077 1.10303 216.043 1.22306 216.035 1.2745L214.337 10.8428H214.277L211.002 1.28736C210.95 1.16304 210.86 1.10303 210.723 1.10303H207.298C207.161 1.10303 207.126 1.22306 207.118 1.2745L204.297 17.1874C204.293 17.2131 204.293 17.256 204.318 17.286C204.34 17.3117 204.383 17.3289 204.43 17.3289H207.662C207.739 17.3289 207.799 17.2646 207.817 17.1617L209.518 7.58907H209.6L212.875 17.1488C212.909 17.2731 212.991 17.3289 213.132 17.3289H216.579C216.712 17.3289 216.746 17.2217 216.759 17.1617L219.58 1.24878C219.584 1.21877 219.584 1.18019 219.558 1.15018L219.554 1.1459Z"
        fill="#00BEFA"
      />
      <path
        d="M228.124 0.943359C225.303 0.943359 223.074 2.07081 221.805 4.67723C220.934 6.43914 220.231 10.9018 220.231 12.4536C220.231 15.7674 222.345 17.4821 225.539 17.4821C228.334 17.4821 230.567 16.3547 231.857 13.7483C232.728 11.9864 233.431 7.52371 233.431 5.97186C233.431 2.65811 231.317 0.943359 228.124 0.943359ZM228.681 2.03223C228.711 1.99364 228.767 2.01937 228.762 2.06652C228.732 2.06224 228.702 2.05366 228.672 2.0408V2.03223H228.681ZM224.977 16.3933C224.947 16.4318 224.891 16.4061 224.896 16.359V16.3204C224.938 16.3247 224.981 16.329 225.024 16.329L224.977 16.389V16.3933ZM225.62 15.9817L225.989 10.756H223.155L227.832 2.44377L227.485 7.41654H230.507L225.624 15.9817H225.62Z"
        fill="#00BEFA"
      />
    </svg>
  )
}

export default Ankerslogen
